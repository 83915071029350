.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  z-index: 999999999;
}

.row-dragging td {
  padding: 12px 8px;
  font-size: 14px;
}

.row-dragging .drag-visible {
  visibility: visible;
}