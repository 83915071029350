.popover {
  position: relative !important;
  width: 400px !important;
}

.noticeButton {
  cursor: pointer;
  transition: all 0.3s;
}

.icon {
  padding: 4px;
  vertical-align: middle !important;
}

.badge {
  font-size: 16px !important;
  padding: 0px !important;
}
