@import "~antd/lib/style/themes/default.less";

.sidebar {
  position: fixed;
  max-height: 90vh;
  min-height: 90vh;
  overflow-x: hidden;
  left: 0;
  top: 55px;

  .sidebar-logo {
    height: 55px;
    display: flex;
    padding: 0rem 0.5rem;
    align-items: center;
    justify-content: center;
  }
  
}

  .ant-menu {
    background-color: transparent;
  }

  ul li.ant-menu-submenu {
    .ant-menu-submenu-title {
      padding: 0rem 1rem !important;
    }

    ul li.ant-menu-item {
      padding-left: 2.5rem !important;
    }
  }

.trigger {
  :hover {
    color: @primary-color !important;
  }
}