.ant-input-group-wrapper {
  display: inline-block;
  width: 100%;
  text-align: start;
  vertical-align: top;
}

.ant-input-group {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0,0,0,0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: relative;
  display: table;
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.ant-input-group-addon.disabled {
  cursor: not-allowed !important;
}

.ant-input-group .ant-select {
  width: 100% !important;
}

.ant-input-group .ant-select .ant-select-selection {
  border-radius: 4px 0px 0px 4px !important;
}

.ant-input-group-addon {
  position: relative;
  padding: 0 11px;
  color: rgba(0,0,0,0.65);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.ant-input-group-addon:hover {
  cursor: pointer;
}

.ant-input-affix-wrapper .ant-input {
  min-height: auto !important;
}

.ant-select-selection-item {
  white-space: normal !important;
}
