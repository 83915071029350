@import (inline) '~bootstrap/dist/css/bootstrap.min.css';
@import (inline) '~react-phone-number-input/style.css';
@import (inline) '~react-loading-bar/dist/index.css';
@import (inline) '~rc-color-picker/assets/index.css';
@import (inline) '~braft-editor/dist/index.css';
@import (inline) "~braft-extensions/dist/table.css";
@import (inline) "~braft-extensions/dist/code-highlighter.css";
@import (inline) '~braft-extensions/dist/color-picker.css';
@import (inline) "~slick-carousel/slick/slick.css"; 
@import (inline) "~slick-carousel/slick/slick-theme.css";
@import (inline) "~video-react/dist/video-react.css"; 
@import "./vars.less";

body {
  background-color: @body-background !important;
}

.anticon svg {
  display: flex !important;
}

.content-center-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.ant-layout-sider-dark {
  background: @layout-header-background !important;
}

[data-theme="dark"] .site-layout-sub-header-background {
    background: @layout-header-background;
}

.container > * {
  box-shadow: none !important;
}

a {
  color: @link-color !important;
}

[data-theme="dark"] a {
  color: @white !important;
} 

.text-primary {
  color: @link-color !important;
}

.ant-form-item {
  margin-bottom: 10px !important;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none !important;
}

.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: @component-background !important;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  border-radius: 5px !important;
  font-size: 15px;
  font-weight: 600 !important;
  padding: 8px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

a:hover {
  text-decoration: none !important;
}

.PhoneInput {
  line-height: 1.5715;
  max-height: 32px !important;
}

.PhoneInputInput:hover, .PhoneInputInput:focus {
  border: none !important;
  outline-width: 0 !important;
}

.PhoneInputInput {
  border: 0px !important;
  background: transparent !important;
}

.PhoneInputCountrySelect {
  background: @component-background !important;
}

h4.ant-typography {
  font-weight: 500 !important;
}

.bf-container {
  border: 1px solid @border-color-base;
  border-radius: 5px;
}

.ant-form-item-has-error .bf-container {
  border: 1px solid @error-color;
  border-radius: 5px;
}

.bf-container .public-DraftEditor-content>div {
  padding-bottom: 0px !important; 
}

.bf-content {
  font-size: 14px;
  height: auto;
  padding-bottom: 0px !important;
  min-height: 250px !important;
}

.ant-form-item-has-error .ant-upload {
  border-color: @error-color !important;
}

.ant-upload.ant-upload-select-picture-card {
  width: 104px;
  min-height: 104px;
  height: 100% !important;
}

.avatar-uploader img{
  width: 100% !important;
  object-fit: contain !important;
} 

.table-responsive .ant-table-cell {
  min-width: 70px !important;
}

.table-responsive .action .ant-table-cell {
  min-width: 50px !important;
}

.main-table .ant-table-scroll .ant-table-body:hover {
  overflow-y: auto !important;
}

.ant-table-thead > tr > th {
  white-space: nowrap;
}

.error .ant-form-item-explain {
  color: @error-color;
}

.error .ant-table {
  border: 1px solid @error-color;
}

/* width */
::-webkit-scrollbar {
  height: 7px;           
  width: 7px;  
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.visible {
  opacity: 1 !important; 
  visibility: visible !important;
}

.ant-page-header-heading-extra > * {
  margin-left: 0px !important;
}

.ant-page-header-heading-sub-title {
  margin-right: 2px !important;
}

.fixed-bottom-back-to-top {
  position: fixed;
  right: 100px !important;
  bottom: 0;
  z-index: 1030;
}

.editor-text p {
  margin-bottom: 0.2rem;
}


.otp .input-style {
  width: 4rem !important;
  height: 4rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 0px;
  border: 1px solid rgba(0,0,0,0.3);
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
}

.otp input:focus-visible {
  outline: none
}

.otp .error {
  border-bottom: 1px solid @error-color !important;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
}

.card-selected {
  border-color: @primary-color !important;
}