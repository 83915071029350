.headerSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
  margin-left: 0px;

  .anticon-search svg {
    font-size: 16px !important;
  }

  :global(.anticon-search) {
    font-size: 16px;
    cursor: pointer;
  }
  .input {
    width: 0;
    background: transparent !important;
    border-radius: 0;
    transition: width 0.3s, margin-left 0.3s;
    color: rgb(255, 255, 255) !important;
    :global(.ant-select-selection) {
      background: transparent !important;
    }
    input {
      padding-right: 0;
      padding-left: 0;
      border: 0;
      box-shadow: none !important;
    }
    &,
    &:hover,
    &:focus {
      border-bottom: 0px solid #d9d9d9;
    }
    &.show {
      width: 160px;
      border-radius: 10px;
      input { 
        padding: 4px 11px;
        background: transparent !important;
        border-bottom: 1px solid #d9d9d9;
        color: rgb(255, 255, 255) !important;
      }
    }
  }
}
