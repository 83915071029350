tr:last-child td {
  padding-bottom: 0;
}

.ant-statistic-content {
  font-size: 20px;
  line-height: 28px;
}

.extra {
  float: right;
  margin-bottom: 10px;
}

@media (max-width: 576px) {

  .content {
    display: block;
  }

  .extra {
    float: unset;
    margin-bottom: 10px;
  }
} 