.wrapper-overlay-box {
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 100;
    position: absolute;
    transform: translate(-50%,-50%);
    background: rgba(255, 255, 255, 0.5);
    
    .overlay-box-content {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%,-50%);
    }
  }