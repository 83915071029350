.ant-layout-header {
  height: 55px;
  padding: 0 16px;
  line-height: 55px;
}

.header .ant-menu {
  line-height: 55px !important;
}

.fixed-header {
  top: 0;
  right: 0;
  z-index: 9;
  width: 100% !important;
  display: flex;
  position: fixed;
  transition: width 0.25s;
  
  .ant-menu-horizontal {
    border-bottom: none !important;
  }
}

.header-bar {
  top: 0;
  right: 0;
  z-index: 9;
  width: 100% !important;
  display: flex;
  transition: width 0.25s;
  
  .ant-menu-horizontal {
    border-bottom: none !important;
  }
}

.header-padding {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px 0px 0px !important;
}

.trigger {
  height: 55px;
  cursor: pointer;
  font-size: 1rem;
  color: rgba(255, 255, 255, 0.65);
  padding: 24px 16px;
  transition: all 0.25s, padding 0s;

  :hover {
    color: #fff;
  }
}

.shortcut-create-icon {
  color: #fff;
  font-size: 0.875rem;
  transition: all 0.25s ease-in-out;

  :hover {
    cursor: pointer;
    color: #fff;
    transition: all 0.25s ease-in-out;
  }
}


.profile span.username {
  color: #fff;
}

.profile .anticon {
  color: #fff;
}

.profile:hover {
  cursor: pointer;
  color: #fff;
}

.fixed-header .ant-menu-item {
  padding: 0 15px !important;
}

.ant-drawer-header {
  display: flex;
  padding: 21px 24px;
  align-items: center;
  border-bottom: unset;
  justify-content: space-between;
  
  .ant-drawer-close {
    width: auto;
    height: auto;
    position: unset;
    line-height: initial;
  }
}

.shortcut-create-link {
  display: block;
  margin-bottom: 0.5rem;
}
.shortcut-create-link.active {
  font-weight: 500;
  color: #335e9c !important;
  transition: all 0.25s ease-in-out;

  @media (max-width: 1024px) {
    color: #fff !important;
  }
}